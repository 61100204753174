@font-face {
  font-family: 'Gilroy';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
      url('../assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Gilroy-Regular.woff') format('woff'),
      url('../assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
      url('../assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Gilroy-Medium.woff') format('woff'),
      url('../assets/fonts/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Gilroy-SemiBold.eot');
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
      url('../assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Gilroy-SemiBold.woff') format('woff'),
      url('../assets/fonts/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-display: block;
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
      url('../assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Gilroy-Bold.woff') format('woff'),
      url('../assets/fonts/Gilroy-Bold.ttf') format('truetype');
}
