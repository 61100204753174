@media print {
  @page {
    margin: 1cm;
    size: A4 portrait;
  }

  .pdfContainer {
    width: 1080px;
  }

  .pdfContainer :global(.hideOnPrint) {
    display: none;
  }

  .pdfContainer :global(.recharts-wrapper),
  .pdfContainer :global(.recharts-wrapper) > :global(.recharts-surface),
  .pdfContainer :global(.recharts-legend-wrapper) {
    width: 100% !important;
  }

  .pdfContainer :global(.recharts-text) {
    font-size: 19px;
  }
}
